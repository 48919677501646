"use client";

import { createTheme } from "@mantine/core";

export const theme = createTheme({
  colors: {
    'primary': ['#353f4d', '#313a47', '#2c3540', '#272f39', '#222831', '#1f242c', '#1c2027', '#191d23', '#171a20', '#121519'],
    'secondary': ['#03cdd6', '#02c5ce', '#02bfc8', '#01b8c1', '#00adb5', '#01a2a9', '#019da4', '#01959c', '#018f95', '#01868c'],
    'ocean-blue': ['#7AD1DD', '#5FCCDB', '#44CADC', '#2AC9DE', '#1AC2D9', '#11B7CD', '#09ADC3', '#0E99AC', '#128797', '#147885'],
    'bright-pink': ['#F0BBDD', '#ED9BCF', '#EC7CC3', '#ED5DB8', '#F13EAF', '#F71FA7', '#FF00A1', '#E00890', '#C50E82', '#AD1374'],
  },
});